<template lang="pug">
.profile-refer
  h2 Дружба помогает зарабатывать
  p(style="width: 350px; margin: 30px auto; font-size: 24px; text-align: center;") Приведи друга и получай за это деньги
  LVideo.profile-refer__video(
    src="https://cdn.likeeng.uk/storage/videos/79150d0e-fbfc-4796-9f66-6c0b6378177d.mp4"
    preview="https://cdn.likeeng.uk/storage/images/215cbd07-7e86-4b1c-981c-e37ef7e6554c.png"
  )
  LInput(v-model="link").mt-2
  LButton.mt-2(style="width: 280px;" @click="copyUrl")
    span(v-if="!coppied") Копировать ссылку
    span(v-else) Скопированно

  .profile-refer__socials
    .profile-refer__socials-item(style="background: #3B5998")
      LIcon(name="fb" color="#fff")

    .profile-refer__socials-item(style="background: #455C81")
      LIcon(name="vk" color="#fff")

    .profile-refer__socials-item(style="background: #EE8208")
      LIcon(name="ok" color="#fff")

  .profile-refer__statistic
    p.mb-2(style="font-weight: bold;font-size: 18px; margin-top: 60px;") Статистика по рефералам
    .profile-refer__statistic-dates-wrapper
      span Дата регистрации:
      input(type="date" v-model="start_date")
      span по
      input(type="date" v-model="end_date")



    table.profile-refer__statistic-table
      thead.profile-refer__statistic-header
        tr
          th Логин
          th Дата регистрации
          th Заработано

      tbody.profile-refer__statistic-list
        tr.profile-refer__statistic-list-item(v-for="refer in refersList")
          td {{ refer.email }}
          td {{ getFormattedDate(refer.created_at) }}
          td {{ refer.amount.value }} {{ refer.amount.currency }}

    <infinite-loading @infinite="getReferalList" :identifier="referalId">
      template(slot="spinner")
        .profile-refer__statistic-list-item(style="border-radius: 10px;")

      template(slot="no-more")
        p
      template(slot="no-results")
        p
    </infinite-loading>

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileMyAdvert',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LVideo: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LVideo'),
  },
  created () {
    this.generateLink()
  },
  data () {
    let now = new Date(Date.now())
    let day = now.getDate()
    day = (day.toString().length > 1) ? day : `0${day}`
    let month = now.getMonth() + 1
    month = (month.toString().length > 1) ? month : `0${month}`

    let endDate = `${now.getFullYear()}-${month}-${day}`
    return {
      link: null,
      refersList: [],
      start_date: null,
      end_date: endDate,
      coppied: false,
      refersCursor: null,
      referalId: 1
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS', 'SETTINGS']),
  },
  methods: {
    ...mapActions('user', ['referal', 'getReferalStatistic', 'getReferLink']),
    generateLink () {
      this.getReferLink()
      .then( res => {
        this.link = `https://cdn.likeeng.uk/signup?refer=${res.data.data.referal_link}`
        console.log(res)
      })
    },
    getReferalList (loader) {
      this.getReferalStatistic({ cursor: this.refersCursor, filter: {
        end_date: this.end_date,
        start_date: this.start_date
      } })
      .then(res => {
        if (res.status !== 200) {
          loader.complete()
          return
        }
        this.refersList.push(...res.data.data.data)
        if (res.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.refersCursor = res.data.data.meta.cursor
        }
      })
    },
    getFormattedDate (d) {
      let date = new Date(d)
      return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`
    },
    copyUrl () {
      navigator.clipboard.writeText(this.link).then(() => {
        this.coppied = true
        setTimeout(() => {
          this.coppied = false
        }, 2000)
      }, (err) => {});
    }
  },
  watch: {
    end_date (newVal) {
      this.referalId += 1
    },
    start_date (newVal) {
      this.referalId += 1
      
    }
  }
}
</script>

<style lang="scss">
.profile-refer {
  padding-top: 210px;
  padding-left: 38px;
  padding-bottom: 200px;
  & h2 {
    text-align: center;
    width: 100%;
    font-size: 36px;
    text-transform: none;
  }
  &__video {
    width: 100%;
    height: 320px;
    background: #ccc;
  }
  &__socials {
    display: flex;
    justify-content: center;
    padding: 40px 0 0 0;
    &-item {
      width: 52px;
      height: 52px;
      cursor: pointer;
      margin: 0 10px 15px 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      & .licon_fb, .licon_ok {
        width: 36px;
        height: 36px;
      }
    }
  }

  &__statistic {
    &-dates-wrapper {
      padding: 20px 0 60px 0;
      & span {
        font-weight: bold;
        font-size: 16px;
      }
      & input {
        border: none;
        display: inline-block;
        margin: 0 15px;
        font-family: 'Roboto', sans-serif;
        height: 40px;
        padding: 0 20px;
      }
    }
    &-table {
      width: 100%;
    }
    &-header {
      background: #FFFFFF;
      border-radius: 10px;
      height: 42px;
      
      width: 100%;
      & th {
        text-align: center;
        vertical-align: middle;
        width: 33.3%;
        text-align: left;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
          width: 100px;
        }
      }
    }
    &-list {
      &-item {
        height: 30px;
        & td {
          vertical-align: middle;
          width: 33.3%;
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
            width: 100px;
          }
        }
      }
    }
  }
}
</style>