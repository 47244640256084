<template lang="pug">
.profile-create-post
  .profile-create-post__type-wrapper(v-if="showSwitch")
    .profile-create-post__type(@click="isAdvert = false" :class="{ 'profile-create-post__type_active' : !isAdvert}") Личный
    .profile-create-post__type(@click="isAdvert = true" :class="{ 'profile-create-post__type_active' : isAdvert}") Рекламный
  //- POST TITLE
  LInput.profile-create-post__input(
    placeholder="* Добавить заголовок"
    v-model="title"
    @blur="validateTitle()"
    @focus="clearErrors('title')"
    :error="titleErrorsPull.length > 0"
  )
  p.profile-create-post__error(v-if="!!getErrorMessage(titleErrorsPull)") {{ getErrorMessage(titleErrorsPull) }}
  //- POST SLIDER
  Swiper.profile-create-post__preview-slider(
    :options="swiperOption"
    v-if="hasMedia"
    ref="swiperRef"
  )
    //- MEDIA
    SwiperSlide.profile-create-post__preview-container(v-for="(item, index) in media" :key="index")
      template(v-if="item.type === 'video'")
        .profile-create-post__preview-container-remove(@click="removePrivateVideo(item.uuid)")
          LIcon(name="trash" color="#fff")

        LVideo(
          :src="item.original"
          :preview="item.preview"
          @inControls="$refs.swiperRef.$swiper.allowTouchMove = false"
          @outControls="$refs.swiperRef.$swiper.allowTouchMove = true"
        )

      template(v-if="item.type === 'image'")
        .profile-create-post__preview-container-remove(@click="removeMediaImage(item.uuid)")
          LIcon(name="trash" color="#fff")

        img.profile-create-post__preview-container-image(:src="item.preview" :draggable="false" ondragstart="return false;")
      

    SwiperSlide.profile-create-post__preview-container(v-for="(item, index) in videos" :key="index")
      .profile-create-post__preview-container-remove(@click="removeVideo(index)")
        LIcon(name="trash" color="#fff")

      LVideo(
        v-if="item.video"
        :src="item.video.datauri"
        :preview="item.preview.datauri"
        @inControls="$refs.swiperRef.$swiper.allowTouchMove = false"
        @outControls="$refs.swiperRef.$swiper.allowTouchMove = true"
      )

    SwiperSlide.profile-create-post__preview-container(v-for="(item, index) in images" :key="index")
      .profile-create-post__preview-container-remove(@click="removeImage(index)")
        LIcon(name="trash" color="#fff")

      img.profile-create-post__preview-container-image(:src="item.datauri" :draggable="false" ondragstart="return false;")

    .swiper-button-prev(slot="button-prev" @click="$refs.swiperRef.$swiper.slidePrev()")
    .swiper-button-next(slot="button-next" @click="$refs.swiperRef.$swiper.slideNext()")
    //- POST SLIDER STUB
  template(v-else)
    .profile-create-post__preview-stub Добавьте медиа файлы

  .profile-create-post__upload-wrapper
    LInputFile.profile-create-post__upload(
      @change="previewImages"
      multiple
    )
      .profile-create-post__upload-icon
        LIcon(name="photo")

      .profile-create-post__upload-text {{ $t('Загрузить фото') }}

    .profile-create-post__upload.ml-2(@click="preUploadVideo")
      .profile-create-post__upload-icon
        LIcon(name="video")

      .profile-create-post__upload-text {{ $t('Загрузить видео') }}

  template(v-if="isAdvert")
    LInput.profile-create-post__input.mt-2(
      placeholder="* Добавить хештэги (через пробел)"
      v-model="tags"
      @blur="validateTags()"
      @focus="clearErrors('tags')"
      :error="tagsErrorsPull.length > 0"
    )
    p.profile-create-post__error(v-if="!!getErrorMessage(tagsErrorsPull)") {{ getErrorMessage(tagsErrorsPull) }}
    LAutocomplite.profile-create-post__categories-input.mt-2(
      v-model="category"
      @input="inputCategories"
      placeholder="* Добавить категории"
    )
      template(slot="items")
        .profile-create-post__categories-droplist-item(
          v-if="categoriesList.length > 0"
          v-for="category in categoriesList"
          @click="addCategory(category)"
        ) {{ category.fullname || category.name }}

    p.profile-create-post__error(v-if="!!getErrorMessage(categoriesErrorsPull)") {{ getErrorMessage(categoriesErrorsPull) }}

    .profile-create-post__categories-selected
      .profile-create-post__categories-selected-item(v-for="sc, index in selectedCategories")
        span {{ sc.fullname || sc.name }}
        LIcon(name="close").lfilters__selected-remove(@click="removeSelectedCategory(index)")

  textarea.profile-create-post__textarea(
    v-model="text"
    :placeholder="`* ${$t('Добавить описание')}`"
    @blur="validateText()"
    @focus="clearErrors('text')"
    :error="titleErrorsPull.length > 0"
  )
  p.profile-create-post__error(v-if="!!getErrorMessage(textErrorsPull)") {{ getErrorMessage(textErrorsPull) }}
  template(v-if="isAdvert")
    LInput.profile-create-post__input.mt-2(
      placeholder="* Добавить ссылку товара или сайта партнёра"
      v-model="partnerLink"
      @blur="validatePartnerLink()"
      @focus="clearErrors('tags')"
      :error="partnerLinkErrorsPull.length > 0"
    )
    p.profile-create-post__error(v-if="!!getErrorMessage(partnerLinkErrorsPull)") {{ getErrorMessage(partnerLinkErrorsPull) }}

  LButton.mt-2.profile-create-post__send(theme="red" @click="sendUpdatePost") Сохранить изменения

  LPopup(
    v-if="uploadPopup"
    @close="!uploadPreloader ? uploadPopup = false : uploadPopup = true"
    :class="{ 'profile-create-post__preview-popup': !uploadPreloader }"
  )
    template(v-if="uploadPreloader")
      h3 Загружаем данные
      LIcon.mt-3.profile-create-post__upload-preloader(name="preloader" color="#C91818")

    template(v-else)
      h3 Предпросмотр поста
      LCardShadow.mt-3(v-if="previewPostData === null")
      LCard.mt-3(v-else :data="previewPostData")
      template(v-if="!publicationSet")
        LButton.mt-2(@click="setPostPublish" :preloder="pulicationPreloader" ) {{ $t('Опубликовать') }}

      template(v-if="publicationSet")
        p Пост опубликован
        LButton.mt-2(@click="publicationSet = false; uploadPopup = false" theme="blue") {{ $t('Закрыть') }}



  LPopup(v-if="uploadVideoPopup" @close="uploadVideoPopup = false")
    .profile-create-post__upload-video
      h3 {{ $t('Загрузить видео файл') }}
      LInputFile.profile-create-post__upload.mt-4(
        @change="previewVideoImage"
      )
        .profile-create-post__upload-icon
          LIcon(name="photo")

        .profile-create-post__upload-text {{ $t('Загрузить превью') }}

      p.mt-1.profile-create-post__upload-error(v-if="invalidVideoPreview") Выбирите картинку для превью
      LInputFile.profile-create-post__upload.mt-2(
        @change="previewVideo"
        accept=".mp4"
      )
        .profile-create-post__upload-icon
          LIcon(name="video")

        .profile-create-post__upload-text {{ $t('Загрузить видеофайл') }}
      p.mt-1.profile-create-post__upload-error(v-if="invalidVideo") Выбирите видео файл

      LButton.mt-2.profile-create-post__send(theme="blue" @click="uploadVideoPopup = false") Назад
      LButton.mt-2.profile-create-post__send(theme="red" @click="confirmVideo") Сохранить


</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import validationMixin from '@/mixins/validation.js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'ProfileUpload',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LInputFile: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInputFile'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LVideo: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LVideo'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'), // TODO to composite
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LAutocomplite: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAutocomplite'),
    Swiper,
    SwiperSlide
  },
  mixins: [validationMixin],
  created () {
    this.categories()
    .then( response => {
      if (!!response.data.data.data) {
        response.data.data.data.forEach(item => {
          if (item.categories?.length > 0) {
            item.categories.forEach(subItem => {
              this.categoriesBuffer.push({
                name: subItem.name,
                fullname: `${item.name} > ${subItem.name}`,
                slug: subItem.slug
              })
            })
          } else {
            this.categoriesBuffer.push({
              name: item.name,
              slug: item.slug
            })
          }
        })
      }
    })
    let uuid = this.$route.params.uuid || nulls
    this.getUserPost(uuid).then(res => {
      
      let post = res.data.data.data
      console.log('POST', post)
      this.media = post.media
      console.log('MEDIA', this.media)
      this.title = post.title
      this.text = post.text
      this.isAdvert = post.adv
      console.log(this.isAdvert)
      this.uuid = post.uuid
      this.showSwitch = post.author.type === 'user'
      if (this.isAdvert) {
        this.partnerLink = post.url
        // категории
        this.tags = post.tags.join(' ')
      }

      if (post.categories.length > 0) {
        post.categories.forEach(cat => {
          if (cat.categories.length > 0) {
            cat.categories.forEach(subCat => {
              this.selectedCategories.push({
                name: subCat.name,
                fullname: `${cat.name} > ${subCat.name}`,
                slug: subCat.slug
              })
            })
          } else {
            this.selectedCategories.push({
              name: cat.name,
              slug: cat.slug
            })
            
          }
        })
      }
    })

  },
  data () {
    return {
      showSwitch: false,
      swiperOption: {
        autoHeight: true, //enable auto height
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      isAdvert: true, // Post type private or advert
      preloader: false,
      // post data
      title: null,
      text: null,
      images: [],
      videos: [],
      media: [],
      partnerLink: null,
      tags: null,

      // add video popup
      bufferVideoFile: null,
      uploadPopup: false,
      uploadPreloader: false,

      uuid: null, // post uuid

      uploadVideoPopup: false,

      previewPostData: null,
      category: null,
      categoriesBuffer: [],
      categoriesList: [],
      selectedCategories: [],

      // validation fields
      titleErrorsPull: [],
      textErrorsPull: [],
      tagsErrorsPull: [],
      partnerLinkErrorsPull: [],
      categoriesErrorsPull: [],
      invalidVideoPreview: false,
      invalidVideo: false,
      pulicationPreloader: false,
      publicationSet: false
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS']),
    hasMedia () {
      return this.images.length > 0 || this.videos.length > 0 || this.media.length > 0
    }
  },
  methods: {
    ...mapActions('posts', [
      'createPost',
      'uploadPostImages',
      'publishPost',
      'uploadPostVideos',
      'getUserPost',
      'deleteMedia',
      'updatePost'
    ]),
    ...mapActions('dictonary', ['categories']),
    inizialiseCategoriesBufer (categories) {

    },
    inizializePostData (uuid) {

    },
    getErrorMessage (pull) {
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    clearErrors (field) {
      if (field === 'title') this.titleErrorsPull = []
      if (field === 'text') this.textErrorsPull = []
      if (field === 'tags') this.textErrorsPull = []
      if (field === 'partnerLink') this.textErrorsPull = []
    },
    validateTitle () {
      let valid = true
      if (this.isEmpty(this.title)) {
        this.titleErrorsPull.push({ error: 'required', message: 'заполните заголовок поста'})
        valid = false
      } else {
        let errorIndex = this.titleErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.titleErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateText () {
      let valid = true
      if (this.isEmpty(this.text)) {
        this.textErrorsPull.push({ error: 'required', message: 'заполните описание поста'})
        valid = false
      } else {
        let errorIndex = this.textErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.textErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateTags () {
      let valid = true
      if (this.isEmpty(this.tags)) {
        this.tagsErrorsPull.push({ error: 'required', message: 'заполните тэги поста'})
        valid = false
      } else {
        let errorIndex = this.tagsErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.tagsErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePartnerLink () {
      let valid = true
      if (this.isEmpty(this.partnerLink)) {
        this.partnerLinkErrorsPull.push({ error: 'required', message: 'добавьте ссылку'})
        valid = false
      } else {
        let errorIndex = this.partnerLinkErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.partnerLinkErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateCategories () {
      let valid = true
      if (this.selectedCategories.length === 0) {
        this.categoriesErrorsPull.push({ error: 'required', message: 'добавьте категорию'})
        valid = false
      } else {
        let errorIndex = this.partnerLinkErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.partnerLinkErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateAllPersonal () {
      if (
        this.validateTitle() &&
        this.validateText()
      ) return true
      return false
    },
    validateAllAdvert () {
      if (
        this.validateTags() &&
        this.validatePartnerLink() &&
        this.validateCategories()
      ) return true
      return false
    },
    sendUpdatePost () {
      console.log('1')
      this.publicationSet = true
      if (!this.validateAllPersonal()) return
      console.log('validateAllPersonal')
      let postData = {
        adv: this.isAdvert,
        title: this.title,
        text: this.text,
        uuid: this.uuid
      }
      console.log(postData)
      if (this.isAdvert) {
        console.log('isAdvert')
        if (!this.validateAllAdvert()) return
        if (this.tags !== null) {
          postData['tags'] = this.tags.split(' ')
        }
        postData['categories'] = []
        this.selectedCategories.forEach(item => {
          postData['categories'].push(item.slug)
        })
        postData['url'] = this.partnerLink
      }
      console.log('uploadPull')
      let uploadPull = []
      this.uploadPreloader = true
      this.uploadPopup = true

      if (this.images.length > 0) {
        this.images.forEach(item => {
          let formdata = new FormData()
          formdata.append('preview', item.data)

          uploadPull.push(this.uploadPostImages({
            uuid: this.uuid,
            data: formdata
          }))

        })
      }

      if (this.videos.length > 0) {
        this.videos.forEach(item => {
          let formdata = new FormData()
          formdata.append('preview', item.preview.data)
          formdata.append('video', item.video.data)
          uploadPull.push(this.uploadPostVideos({
            uuid: this.uuid,
            data: formdata
          }))
        })
      }

      console.log('before promise uploadPull')
      Promise.all(uploadPull)
      .then(values => {
        this.updatePost(postData)
        .then(res => {
          this.getUserPost(this.uuid)
          .then( response => {
            this.uploadPreloader = false
            this.previewPostData = response.data.data.data
          })
        })
      })


      

    },
    sendPost () {
      this.publicationSet = false
      // validation
      if (!this.validateAllPersonal()) return
      let postData = {
        adv: this.isAdvert,
        title: this.title,
        text: this.text
      }
      if (this.isAdvert) {
        if (!this.validateAllAdvert()) return
        if (this.tags !== null) {
          postData['tags'] = this.tags.split(' ')
        }
        postData['categories'] = []
        this.selectedCategories.forEach(item => {
          postData['categories'].push(item.slug)
        })
        postData['url'] = this.partnerLink
      }
      this.createPost(postData)
      .then(response => {
        this.uuid = response.data.data.uuid
        let uploadPull = []
        this.uploadPreloader = true
        this.uploadPopup = true
        // preloader on
        if (this.images.length > 0) {
          this.images.forEach(item => {
            let formdata = new FormData()
            formdata.append('preview', item.data)

            uploadPull.push(this.uploadPostImages({
              uuid: this.uuid,
              data: formdata
            }))

          })
        }

        if (this.videos.length > 0) {
          this.videos.forEach(item => {
            let formdata = new FormData()
            formdata.append('preview', item.preview.data)
            formdata.append('video', item.video.data)
            uploadPull.push(this.uploadPostVideos({
              uuid: this.uuid,
              data: formdata
            }))
          })
        }

        Promise.all(uploadPull)
        .then(values => {
          this.uploadPreloader = false
          this.getUserPost(this.uuid)
          .then( response => {
            this.previewPostData = response.data.data.data
            this.clearData()
          })
        })
      })
    },
    clearData() {
      this.title = null
      this.text = null
      this.images = []
      this.videos = []
      this.partnerLink = null
      this.tags = null
      this.bufferVideoFile = null
      this.category = null
      this.selectedCategories = []
    },
    removeImage (index) {
      this.images.splice(index, 1)
    },
    removeVideo (index) {
      this.videos.splice(index, 1)
    },
    preUploadVideo () {
      this.uploadVideoPopup = true
      this.bufferVideoFile = {
        type: 'video',
        preview: null,
        video: null
      }
    },

    previewImages (e) {
      const loadFile = (file, index) => {
        var reader  = new FileReader();
        reader.onload = () => {
          this.images.push({
            data: file,
            datauri: reader.result,
            type: 'image'
          })
        }
        reader.readAsDataURL(file);
      }
      if (e.files.length > 0) {
        e.files.forEach((item, index) => {
          loadFile(item, index)
        })
      }
    },
    previewVideoImage (e) {
      this.invalidVideoPreview = false
      const loadFile = (file, index) => {
        var reader  = new FileReader();
        reader.onload = () => {
          this.bufferVideoFile.preview = {
            data: file,
            datauri: reader.result
          }
        }
        reader.readAsDataURL(file);
      }
      if (e.files.length > 0) {
        e.files.forEach((item, index) => {
          loadFile(item, index)
        })
      }
    },
    previewVideo (e) {
      this.invalidVideo = false
      const loadFile = (file, index) => {
        var reader  = new FileReader();
        reader.onload = (e) => {
          let buffer = e.target.result;
          let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });



          this.bufferVideoFile.video = {
            data: file,
            datauri: window.URL.createObjectURL(videoBlob)
          }
        }
        reader.readAsArrayBuffer(file);

      }
      if (e.files.length > 0) {
        e.files.forEach((item, index) => {
          loadFile(item, index)
        })
      }
    },
    setPostPublish() {
      this.pulicationPreloader = true
      this.publishPost(this.uuid)
      .then(() => {
        this.pulicationPreloader = false
        this.publicationSet = true
        this.clearData()
      })
    },
    validateVideoPopup () {
      let valid = true
      if (this.bufferVideoFile.preview === null) {
        this.invalidVideoPreview = true
        valid = false
      }
      if (this.bufferVideoFile.video === null) {
        this.invalidVideo = true
        valid = false
      }
      return valid
    },
    confirmVideo () {
      if (!this.validateVideoPopup()) return
      this.videos.push(this.bufferVideoFile)
      console.log('videos', this.videos)
      this.bufferVideoFile = null
      this.uploadVideoPopup = false
    },
    inputCategories (query) {
      this.categoriesList = []
      console.log(this.categoriesBuffer)
      if (query.length > 0) {
        this.categoriesBuffer.forEach(item => {
          if(item.name.toLowerCase().indexOf(query.toLowerCase()) + 1) {
            if (this.selectedCategories.indexOf(item) < 0) {
              this.categoriesList.push(item)
            }
          }
        })
      }
    },
    addCategory (category) {
      let hasCategory = false
      this.selectedCategories.forEach(cat => {
        if (cat.slug === category.slug) hasCategory = true
      })
      if (!hasCategory) this.selectedCategories.push(category)
      this.category = null
      this.categoriesList = []
    },
    removeSelectedCategory (index) {
      this.selectedCategories.splice(index, 1)
      console.log('AFTER REMOVE', this.selectedCategories)
    },
    removeMediaImage (uuid) {
      this.deleteMedia(uuid)
      .then(res => {
        let idx = this.media.findIndex(item => {
          return item.uuid === uuid
        });
        if (idx >= 0) this.media.splice(idx, 1)
      })
    },
    removePrivateVideo (uuid) {
      this.deleteMedia(uuid)
      .then(res => {
        let idx = this.media.findIndex(item => {
          return item.uuid === uuid
        });
        if (idx >= 0) this.media.splice(idx, 1)
      })
    }
  },
  watch: {
    SETTINGS (newVal) {
      console.log('test', newVal)
    }
  }
}
</script>

<style lang="scss">
.profile-create-post {
  padding-top: 210px;
  max-width: 660px;
  margin-left: 38px;
  padding-bottom: 60px;

  &__categories {
    &-input {
      & .linput {
        border: solid 1px #757373;
        height: 52px !important;
        & input::placeholder {
          text-transform: none !important;
        }
        &.linput_focused {
          border: solid 1px #1E4BD2 !important;
        }
      }
    }
    &-droplist-item {
      padding: 10px;
      &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0, .1);
      }
    }
    &-selected {
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px;
      &-item {
        font-size: 12px;
        background-color: #EBEBEB;
        border-radius: 5px;
        display: flex;
        padding: 5px;
        align-items: center;
        margin-right: 5px;
        margin-top: 5px;
        & .licon {
          width: 10px;
          height: 10px;
          cursor: pointer;
          margin-left: 5px;
        }
      }
      &-remove {

      }
    }
  }
  &__preview-popup {
    & .lpopup__body {
      max-width: 660px;
      min-width: 660px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      & > h3 {
        width: 100%;
        text-align: center;
      }
      & > .lbutton {
        width: 100%;
      }
      & > .lcard {
        width: 100%;
      }
    }
  }
  &__upload-preloader {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: block;
  }
  &__tabs {
    & .ltabs__header {
      width: 40%;
      margin-bottom: 46px;
    }
  }

  &__input {
    line-height: 52px;
    border: solid 1px #c4c4c4;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    border: solid 1px #757373 !important;
    height: 52px !important;
    & input::placeholder {
      text-transform: none !important;
    }
    &.linput_focused {
      border: solid 1px #1E4BD2 !important;
    }
  }
  &__textarea {
    border: solid 1px #757373;
    border-radius: 10px;
    display: block;
    margin-top: 18px;
    width: 100%;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    min-height: 170px;
    font-family: 'Roboto', sans-serif;
    &::placeholder  {
      font-size: 18px;
    }
  }

  &__preview {
    &-stub {
      min-height: 370px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #C4C4C4;
      border-radius: 10px;
      margin: 18px 0;
    }
    &-slider {
      width: 100%;
      margin: 18px 0;
      height: auto;
      overflow: hidden;
      position: relative;
      & .slick-slide {
        margin-left: 15px;
      }
      & .slick-track {
        margin-left: -15px;
      }
    }
    &-container {
      width: 100%;
      height: auto;
      position: relative;
      &-remove {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: solid 2px #fff;
        background: #C91818;
        z-index: 20;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-image {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  &__send {
    width: 270px;
    margin-left: auto;
  }
  &__upload {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    &-error {
      color: #C91818;
    }
    &-wrapper {
      display: flex;
    }
    &-icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #c4c4c4;
      border-radius: 10px;
      cursor: pointer;
      & > .licon {
        width: 40px;
        height: 40px;
        & path {
          fill: #fff;
        }
      }
    }
    &-text {
      margin-left: 15px;
      color: #757373;
    }
  }
  &__label {
    margin-left: 15px;
    color: #757373;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    height: 60px;
    margin-bottom: 30px;
  }

  &__type {
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    &-wrapper {
      display: flex;
      width: 40%;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      margin-bottom: 47px;
      margin-top: -100px;
      position: relative;
    }
    &_active {
      font-weight: 700;
      position: relative;
      &:after {
        position: absolute;
        bottom: 0;
        top: 22px;
        left: 0;
        right: 0;
        height: 3px;
        width: 100%;
        content: '';
        background: #C31A14;
        opacity: .6;
      }
    }
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }

}

</style>