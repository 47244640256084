<template lang="pug">
.user
  .user__wrapper
    .user__sidebar
      LCategories
      LFilters

    .user__content(:key="rerender")
      .user__nav
        .user__nav-item(@click="$router.push('/app/')") {{ $t('Реклама') }}
        .user__nav-item(@click="$router.push('/app/followings')") {{ $t('Подписки') }}
        .user__nav-item(@click="$router.push('/app/all')") {{ $t('Общее') }}

      LUserView(:uuid="uuid")
      LCard(
        v-for="item, index in posts"
        :data="item"
        :key="index"
        :canFollow="false"
      )
      <infinite-loading @infinite="getUserFeed" :identifier="feedId">
        template(slot="spinner")
          LCardShadow

        template(slot="no-more")
          p

        template(slot="no-result")
          p

      </infinite-loading>

    .user__banners

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'User',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LDropdown: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LDropdown'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
    LFilters: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LFilters'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LVideo: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LVideo'),
    LUserView: () => import(/* webpackChunkName: "ui" */ '@/components/composite/LUserView'),
    LCategories: () => import(/* webpackChunkName: "ui" */ '@/components/composite/LCategories'),
  },
  created () {
    this.uuid = this.$route.params.uuid
  },
  data () {
    return {
      feedPrealoder: true,
      userPreloader: true,
      currentCursor: null,
      feedId: 1,
      posts: [],
      uuid: null,
      rerender: 1
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('posts', ['userFeed']),
    getUserFeed (loader) {
      this.userFeed({ cursor: this.currentCursor, filters: this.filters, uuid: this.uuid })
      .then((response) => {
        this.posts.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.currentCursor = response.data.data.meta.cursor
        }
      })
    }
  },
  watch: {
    $route (newVal) {
      this.uuid = newVal.params.uuid
      this.rerender += 1
      this.posts = []
      this.currentCursor = null
    }
  }
}
</script>

<style lang="scss">
.user {
  &__wrapper {
    width: 100%;
    display: flex;
    max-width: 1170px;
    margin: 0 auto;
  }
  &__sidebar {
    width: 300px;
    padding-top: 100px;
  }
  &__content {
    width: calc(100% - 440px);
    box-sizing: border-box;
    padding: 0 30px;
    padding-top: 40px;
    & .ltabs__header {
      width: 50%;
    }
  }
  &__nav {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 10px;
    &-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
  }
}
</style>