<template lang="pug">
.profile-settings
  template(v-if="SETTINGS === null")
    p

  template(v-else)
    .profile-settings__user
      LAvatar(size="large" 
        :url="SETTINGS.photo.preview"
        upload
        @upload="uploadPhoto"
      )
        LInputFile(@change="startCrop")
          .profile-settings__upload
            LIcon(:name="!uploadPreloader ? 'photo' : 'preloader'" :color="!uploadPreloader ? '#fff' : '#C91818'")

      .profile-settings__username.mb-1.mt-2 Дата регистрации
      .profile-settings__signup-date 21.10.2021

    .profile-settings__inputs
      .profile-settings__inputs-item
        .profile-settings__inputs-data-wrapper
          span Имя / Фамилия
          span {{ SETTINGS.name }}

        LButton(theme="blue" size="small" @click="namePopup = true") Изменить

    .profile-settings__inputs
      .profile-settings__inputs-item
        .profile-settings__inputs-data-wrapper
          span Логин
          span {{ SETTINGS.username }}

        LButton(theme="blue" size="small" @click="loginPopup = true") Изменить

    .profile-settings__inputs
      .profile-settings__inputs-item
        .profile-settings__inputs-data-wrapper
          span Телефон
          span {{ SETTINGS.phone }}

        LButton(theme="blue" size="small" @click="phonePopup = true") Изменить

    .profile-settings__inputs
      .profile-settings__inputs-item
        .profile-settings__inputs-data-wrapper
          span Эл-почта
          span {{ SETTINGS.email }}

    .profile-settings__inputs
      .profile-settings__inputs-item
        .profile-settings__inputs-data-wrapper
          span Пароль
          span ********

        LButton(theme="blue" size="small" @click="passwordPopup = true; forgotPasswordView = false") Изменить

    .profile-settings__inputs
      .profile-settings__inputs-item
        .profile-settings__deactivate.link Деактивировать аккаунт

    .profile-settings__links

    //- CROP PHOTO
    LPopup(
      v-if="photoPopup"
      @close="photoPopup = false"
    )
        .signup-additional__crop
          VueCroppie(
            ref="croppieRef"
            :enableOrientation="true"
            :boundary="{ width: 256, height: 256 }"
            :enableResize="false"
            :viewport="{ width: 256, height: 256, type: 'circle' }"
          )
          LButton.mt-2(@click="crop") {{ $t('Отправить') }}

    //- CHANGE FIRST AND LAST NAMES
    LPopup(
      v-if="namePopup"
      @close="namePopup = false"
    )
      h3.profile-settings__popup-header.mt-3.mb-3 Изменение имени
      LInput(
        v-model="name"
        placeholder="Имя Фамилия"
        v-focus
        @blur="validateName"
        @focus="clearErrors('name')"
        :error="nameErrorsPull.length > 0"
      )
      p.profile-settings__error(v-if="nameErrorsPull.length > 0") {{ getErrorMessage(nameErrorsPull) }}
      LButton.mt-4(:preloader="sendPreloader" @click="sendName") {{ $t('Поменять имя') }}
      LButton.mt-2(theme="blue" @click="namePopup = false; sendPreloader = false") {{ $t('Отменить') }}


    //- CHANGE LOGIN
    LPopup(
      v-if="loginPopup"
      @close="loginPopup = false"

    )
      h3.profile-settings__popup-header.mt-3.mb-3 Изменение логина
      LInput(
        v-model="username"
        placeholder="Логин"
        v-focus
        @blur="validateUsername"
        @focus="clearErrors('username')"
        :error="usernameErrorsPull.length > 0"
      )
      p.profile-settings__error(v-if="usernameErrorsPull.length > 0") {{ getErrorMessage(usernameErrorsPull) }}
      LButton.mt-4(:preloader="sendPreloader" @click="sendUsername") {{ $t('Поменять логин') }}
      LButton.mt-2(theme="blue" @click="loginPopup = false; sendPreloader = false") {{ $t('Отменить') }}

    //- CHANGE PHONE
    LPopup(
      v-if="phonePopup"
      @close="phonePopup = false"
    )
      h3.profile-settings__popup-header.mt-3.mb-3 Изменение телефона
      LInput(
        v-model="phone"
        placeholder="Телефон"
        v-focus
        @blur="validatePhone"
        @focus="clearErrors('phone')"
        :error="phoneErrorsPull.length > 0"
      )
      p.profile-settings__error(v-if="phoneErrorsPull.length > 0") {{ getErrorMessage(phoneErrorsPull) }}
      LButton.mt-4(:preloader="sendPreloader" @click="sendPhone") {{ $t('Поменять телефон') }}
      LButton.mt-2(theme="blue" @click="phonePopup = false; sendPreloader = false") {{ $t('Отменить') }}


    //- CHANGE PASSWORD
    LPopup(
      v-if="passwordPopup"
      @close="passwordPopup = false"
    ).profile-settings__change-password
      template(v-if="!forgotPasswordView")
        h3.profile-settings__popup-header.mt-3.mb-3 Изменение пароля
        LInput.mt-4(
          v-model="oldPassword"
          placeholder="Старый пароль"
          v-focus
          type="password"
          @blur="validatePassword"
          @focus="clearErrors('oldPassword')"
          :error="oldPasswordErrorsPull.length > 0"
        )
        p.profile-settings__error(v-if="oldPasswordErrorsPull.length > 0") {{ getErrorMessage(oldPasswordErrorsPull) }}
        LButton.mt-2.profile-settings__change-password-forgot(
          size="small"
          theme="transparent"
          @click="forgotPasswordHandler"
          :preloader="sendEmailPreloader"
        ) {{ $t('forgot password') }}?
        LInput.mt-3(
          v-model="newPassword"
          placeholder="Новый пароль"
          type="password"
          @blur="validateNewPassword"
          @focus="clearErrors('newPassword')"
          :error="newPasswordErrorsPull.length > 0"
        )
        p.profile-settings__error(v-if="newPasswordErrorsPull.length > 0") {{ getErrorMessage(newPasswordErrorsPull) }}
        LInput.mt-1(
          v-model="confirmPassword"
          placeholder="Подтвердить пароль"
          type="password"
          @blur="validateConfirmPassword"
          @focus="clearErrors('confirmPassword')"
          :error="confirmPasswordErrorsPull.length > 0"
        )
        p.profile-settings__error(v-if="confirmPasswordErrorsPull.length > 0") {{ getErrorMessage(confirmPasswordErrorsPull) }}
        LButton.mt-2(:preloader="sendPreloader" @click="sendPassword") Поменять пароль
        LButton.mt-2(theme="blue" @click="cancel") Отмена

      template(v-else)
        h3.profile-settings__popup-header.mt-3.mb-3 Забыли пароль
        LTimer.profile-settings__timer.mt-4(
          v-if="showTimer"
          :count="6000"
          :timestamp="Date.now()"
          @end="showTimer = false"
          :class="{'profile-settings__timer_alert': alert}"
        )
        .profile-settings__resend(@click="resendCode") {{ $t('resend code') }}
        LInput.mt-3(
          v-model="forgotCode"
          placeholder="Введите код"
          @blur="validateCode"
          @focus="clearErrors('code')"
          :error="codeErrorsPull.length > 0"
        )
        p.profile-settings__error(v-if="codeErrorsPull.length > 0") {{ getErrorMessage(codeErrorsPull) }}
        LInput.mt-2(
          v-model="forgotNewPassword"
          placeholder="Новый пароль"
          type="password"
          @blur="validateForgotNewPassword"
          @focus="clearErrors('forgotNewPassword')"
          :error="forgotNewPasswordErrorsPull.length > 0"
        )
        p.profile-settings__error(v-if="forgotNewPasswordErrorsPull.length > 0") {{ getErrorMessage(forgotNewPasswordErrorsPull) }}
        LInput.mt-1(
          v-model="forgotConfirmPassword"
          placeholder="Подтвердите пароль"
          type="password"
          @blur="validateForgotConfirmPassword"
          @focus="clearErrors('forgotConfirmPassword')"
          :error="forgotConfirmPasswordErrorsPull.length > 0"
        )
        p.profile-settings__error(v-if="forgotConfirmPasswordErrorsPull.length > 0") {{ getErrorMessage(forgotConfirmPasswordErrorsPull) }}
        LButton.mt-2(:preloader="forgotSendPreloader" @click="forgotSend") Отправить
        LButton.mt-2(theme="blue" @click="forgotPasswordView = false") Назад

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import validationMixin from '@/mixins/validation.js'

export default {
  name: 'ProfileSettings',
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LInputFile: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInputFile'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LTimer: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTimer')
  },
  mixins: [validationMixin],
  data () {
    return {
      photoPopup: false,
      namePopup: false,
      loginPopup: false,
      phonePopup: false,
      passwordPopup: false,
      croppieRef: null,
      uploadPreloader: false,
      showTimer: true,
      alert: false,

      usernameErrorsPull: [],
      nameErrorsPull: [],

      oldPasswordErrorsPull: [],
      newPasswordErrorsPull: [],
      confirmPasswordErrorsPull: [],
      codeErrorsPull: [],
      forgotNewPasswordErrorsPull: [],
      forgotConfirmPasswordErrorsPull: [],

      phoneErrorsPull: [],

      sendPreloader: false,
      forgotSendPreloader: false,

      name: null,
      username: null,
      phone: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      newPassword: null,
      confirmPassword: null,

      forgotPasswordView: false,
      forgotCode: null,
      forgotNewPassword: null,
      forgotConfirmPassword: null,
      sendEmailPreloader: false,
      codeErrorsPull: [],
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS', 'USER_DATA']),
  },
  methods: {
    ...mapActions('user', ['uploadPhoto', 'setUserPhoto', 'updateSettings', 'recovery', 'changePassword', 'resendConfirmCode', 'recoverySendCode']),
    alertHandler () {
      this.alert = true
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    resendCode () {
      if (this.showTimer) {
        this.alertHandler()
        return
      }
      this.resendConfirmCode({
        email: this.SETTINGS.email
      })
      .then((response) => { this.showTimer = true })
    },
    forgotPasswordHandler() {
      this.sendEmailPreloader = true
      this.recovery(this.SETTINGS.email)
      .then(r => {
        this.forgotPasswordView = true
        this.sendEmailPreloader = false
        this.showTimer = true
      })
    },
    startCrop (ipt) {
      this.photoPopup = true
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

    reader.readAsDataURL(ipt.files[0]);
    },
    clearErrors (field) {
      if (field === 'username') this.usernameErrorsPull = []
      if (field === 'name') this.nameErrorsPull = []
      if (field === 'phone') this.phoneErrorsPull = []
      if (field === 'oldPassword') this.oldPasswordErrorsPull = []
      if (field === 'newPassword') this.newPasswordErrorsPull = []
      if (field === 'confirmPassword') this.confirmPasswordErrorsPull = []
      if (field === 'code') this.codeErrorsPull = []
      if (field === 'forgotNewPassword') this.forgotNewPasswordErrorsPull = []
      if (field === 'forgotConfirmPassword') this.forgotConfirmPassword = []
    },
    crop () {
      let options = {
        type: 'blob',
        size: { width: 256, height: 256 },
        format: 'png'
      };
      this.$refs.croppieRef.result(options, output => {
        this.croppedImage = output;
        this.photoPopup = false;
        this.upload(output)
      })
    },
    upload (blobData) {
      this.uploadPreloader = true
      let data = new FormData()
      data.append('file', blobData);
      this.uploadPhoto(data)
      .then((response) => {
        this.uploadPreloader = false
        this.setUserPhoto(response.data.data.preview)
      })
    },
    sendName () {
      if (!this.validateName()) return
      this.sendPreloader = true
      this.updateSettings({
        "name": this.name
      })
      .then((response) => {
        this.sendPreloader = false
        this.namePopup = false
        this.name = null
      })
    },
    sendUsername () {
      if (!this.validateUsername()) return
      this.sendPreloader = true
      this.updateSettings({
        "username": this.username
      })
      .then((response) => {
        if (response.status === 200) { // its ok
          this.loginPopup = false
        }
        if (!response.data.result.status) { // validation error
          if (response.data.result.message === 'The username has already been taken.') { // bad
            this.usernameErrorsPull.push({ error: 'taken', message: 'Это имя пользователя уже занято'})
          }
        }

      })
      .finally(() => {
        this.sendPreloader = false
        this.username = null
      })
    },
    sendPhone () {
      if (!this.validatePhone()) return
      this.sendPreloader = true
      this.updateSettings({
        "phone": this.phone
      })
      .then((response) => {
        this.sendPreloader = false
        this.phonePopup = false
        this.phone = null
      })
    },
    getErrorMessage (pull) {
      if (typeof pull[0] !== 'undefined') {
        return pull[0].message
      }
      return false
    },
    validateName () {
      let valid = true
      // required
      if (this.isEmpty(this.name)) {
        this.nameErrorsPull.push({ error: 'required', message: 'заполните поле логин'})
        valid = false
      } else {
        let errorIndex = this.nameErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.nameErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateUsername () {
      let valid = true
      // required
      if (this.isEmpty(this.username)) {
        this.usernameErrorsPull.push({ error: 'required', message: 'заполните поле логин'})
        valid = false
      } else {
        let errorIndex = this.usernameErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.usernameErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.username, 5)) {
        this.usernameErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.usernameErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.usernameErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePhone () {
      let valid = true
      // required
      if (this.isEmpty(this.phone)) {
        this.phoneErrorsPull.push({ error: 'required', message: 'заполните поле телефон'})
        valid = false
      } else {
        let errorIndex = this.phoneErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.phoneErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.phone, 10)) {
        this.phoneErrorsPull.push({ error: 'minlength', message: 'минимум 10 символов'})
        valid = false
      } else {
        let errorIndex = this.phoneErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.phoneErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePassword () {
      let valid = true
      if (this.isEmpty(this.oldPassword)) {
        this.oldPasswordErrorsPull.push({ error: 'required', message: 'заполните поле старый пароль'})
        valid = false
      } else {
        let errorIndex = this.oldPasswordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.oldPasswordErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.oldPassword, 5)) {
        this.oldPasswordErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.oldPasswordErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.oldPasswordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && !this.securePassword(this.oldPassword)) {
        this.oldPasswordErrorsPull.push({ error: 'secure', message: 'пароль должен сожержать минимум 1 цифру, минимум 1 букву, и минимум 1 заглавную букву'})
        valid = false
      } else {
        let errorIndex = this.oldPasswordErrorsPull.findIndex((element) => element.error === "secure")
        if (errorIndex >= 0) this.oldPasswordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateNewPassword () {
      let valid = true
      if (this.isEmpty(this.newPassword)) {
        this.newPasswordErrorsPull.push({ error: 'required', message: 'заполните поле новый пароль'})
        valid = false
      } else {
        let errorIndex = this.newPasswordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.newPasswordErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.newPassword, 5)) {
        this.newPasswordErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.newPasswordErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.newPasswordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && !this.securePassword(this.newPassword)) {
        this.newPasswordErrorsPull.push({ error: 'secure', message: 'пароль должен сожержать минимум 1 цифру, минимум 1 букву, и минимум 1 заглавную букву'})
        valid = false
      } else {
        let errorIndex = this.newPasswordErrorsPull.findIndex((element) => element.error === "secure")
        if (errorIndex >= 0) this.newPasswordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateConfirmPassword () {
      let valid = true
      if (this.newPassword !== this.confirmPassword) {
        this.confirmPasswordErrorsPull.push({ error: 'confirm', message: 'пароль не совпадает'})
        valid = false
      } else {
        let errorIndex = this.confirmPasswordErrorsPull.findIndex((element) => element.error === "confirm")
        if (errorIndex >= 0) this.confirmPasswordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateCode () {
      let valid = true
      if (this.isEmpty(this.code)) {
        this.codeErrorsPull.push({ error: 'required', message: 'заполните поле код'})
        valid = false
      } else {
        let errorIndex = this.codeErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.codeErrorsPull.splice(errorIndex, 1)
      }
      if (valid && this.minLength(this.code, 5)) {
        this.codeErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.codeErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.codeErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validatePasswords() {
      if (
        this.validatePassword() &&
        this.validateNewPassword() &&
        this.validateConfirmPassword()
      ) return true
      return false
    },
    validateForgotNewPassword () {
      let valid = true
      if (this.isEmpty(this.forgotNewPassword)) {
        this.forgotNewPasswordErrorsPull.push({ error: 'required', message: 'заполните поле новый пароль'})
        valid = false
      } else {
        let errorIndex = this.forgotNewPasswordErrorsPull.findIndex((element) => element.error === "required")
        if (errorIndex >= 0) this.forgotNewPasswordErrorsPull.splice(errorIndex, 1)
      }
      // min length
      if (valid && this.minLength(this.forgotNewPassword, 5)) {
        this.forgotNewPasswordErrorsPull.push({ error: 'minlength', message: 'минимум 5 символов'})
        valid = false
      } else {
        let errorIndex = this.forgotNewPasswordErrorsPull.findIndex((element) => element.error === "minlength")
        if (errorIndex >= 0) this.forgotNewPasswordErrorsPull.splice(errorIndex, 1)
      }
      if (valid && !this.securePassword(this.forgotNewPassword)) {
        this.forgotNewPasswordErrorsPull.push({ error: 'secure', message: 'пароль должен сожержать минимум 1 цифру, минимум 1 букву, и минимум 1 заглавную букву'})
        valid = false
      } else {
        let errorIndex = this.forgotNewPasswordErrorsPull.findIndex((element) => element.error === "secure")
        if (errorIndex >= 0) this.forgotNewPasswordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateForgotConfirmPassword () {
      let valid = true
      if (this.forgotNewPassword !== this.forgotConfirmPassword) {
        this.forgotConfirmPasswordErrorsPull.push({ error: 'confirm', message: 'пароль не совпадает'})
        valid = false
      } else {
        let errorIndex = this.forgotConfirmPasswordErrorsPull.findIndex((element) => element.error === "confirm")
        if (errorIndex >= 0) this.forgotConfirmPasswordErrorsPull.splice(errorIndex, 1)
      }
      return valid
    },
    validateForgotAll () {
      if (this.validateCode() &&
        this.validateForgotNewPassword() &&
        this.validateForgotConfirmPassword()
      ) return true
      return false
    },
    cancel () {
      this.passwordPopup = false
      this.sendPreloader = false
      this.oldPassword = null
      this.newPassword = null
      this.confirmPassword = null
      this.oldPasswordErrorsPull = []
      this.newPasswordErrorsPull = []
      this.confirmPasswordErrorsPull = []
    },
    sendPassword () {
      if (!this.validatePasswords()) return
      this.sendPreloader = true
      this.changePassword({
        old: this.oldPassword,
        new: this.newPassword
      })
      .then( response => {
        if (response.data?.result?.message === 'Old password not match') {
          this.oldPasswordErrorsPull.push({ error: 'match', message: 'старый пароль не совпадает'})
        }
        this.sendPreloader = false
      })
    },
    forgotSend () {
      if (!this.validateForgotAll()) return
      this.forgotSendPreloader = true
      this.recoverySendCode(
      {
        code_confirm: this.code,
        email: this.SETTINGS.email,
        password: this.forgotNewPassword
      })
      .then (response => {
          console.log(response)
          this.forgotSendPreloader = false
      })
    }
  }
}
</script>

<style lang="scss">
.profile-settings {
  padding-top: 60px;
  width: 100%;
  &__timer {
    font-size: 32px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    &_alert {
      animation: alert 1s infinite ease-in-out;
    }
  }
  &__resend {
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    width: 100px;
    text-align: center;
  }
  &__upload {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 39px;
    height: 36px;
    background: #C4C4C4;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
  &__popup-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__error {
    color: #E12323;
    margin-top: 2px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 60px;
    & .lavatar {
      overflow: visible;
    }
  }
  &__inputs {
    display: flex;
    justify-content: flex-start;
    max-width: 580px;
    margin: 0 auto;
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      font-size: 18px;
    }
    &-data-wrapper {
      display: flex;
      width: 370px;
      justify-content: space-between;
      margin-right: 60px;
    }
  }
  &__change-password {
    & .lpopup__body {
      display: block;
    }
    &-forgot {
      display: inline-flex !important;
      font-weight: 700;
      cursor: pointer;
      & .lpreloader__circle {
        fill: #000 !important;
      }
    }
  }
}
</style>