<template lang="pug">
.profile-photoandvideo
  .profile-photoandvideo__list
    .profile-photoandvideo__item(v-for="item in items" @click="showPost(item.uuid)")
      img(:src="item.preview")

  <infinite-loading @infinite="getGalleryItems" :identifier="galleryId">
    template(slot="spinner")
      p

    template(slot="no-more")
      p

    template(slot="no-results")
      p

  </infinite-loading>
  
  LPopup(
    v-if="showPopup"
    @close="showPopup = false"
    class="profile-photoandvideo__popup"
  )
    LCardShadow(v-if="postData === null")
    LCard(v-else :data="postData")




</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileUpload',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LInputFile: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInputFile')
  },
  data () {
    return {
      items: [],
      itemsCursor: null,
      galleryId: 1,
      showPopup: false,
      postData: null
    }
  },
  computed: {
    ...mapGetters('user', ['USER_DATA']),
  },
  methods: {
    ...mapActions('user', ['getGallery']),
    ...mapActions('posts', ['getUserPost']),
    showPost (uuid) {
      this.postData = null
      this.showPopup = true
      this.getUserPost(uuid)
      .then(res => {
        this.postData = res.data.data.data
      })

    },
    getGalleryItems (loader) {
      this.getGallery({ cursor: this.itemsCursor })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.items.push(...response.data.data.data)
        console.log(this.items)
        if (!!response.data.data.meta && response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.itemsCursor = response.data.data?.meta.cursor
        }
      })
    }


  }
}
</script>

<style lang="scss">
.profile-photoandvideo {
  padding-top: 210px;
  padding-left: 38px;
  min-height: 200px;
  padding-bottom: 60px;
  &__popup {
    & .lpopup__body {
      max-width: 700px;
    }
  }
  &__list {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    gap: 50px 5%;

  }
  &__item {
    height: 180px;
    background: #ccc;
    position: relative;
    overflow: hidden;
    & > img {
      width:100%;
      height:100%;
      position: absolute;
      object-fit: cover;
      object-position: center;
      
    }
  }
}
</style>