<template lang="pug">
.profile-partners
  .profile-partners__search
    LSearch

  .profile-partners__list
    LPartner(
      v-for="partner in partners"
      :name="partner.name"
      :description="partner.username"
      :photo="partner.photo.preview"
      :uuid="partner.uuid"
    )
  <infinite-loading @infinite="getPartnersList" :identifier="partnerId">
    template(slot="spinner")
      .profile-partners__preloader
        LUserShadow(v-for="i in 10")

    template(slot="no-more")
      p

    template(slot="no-results")
      p

  </infinite-loading>





</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Profilefriends',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LSearch: () => import(/* webpackChunkName: "global" */ '@/components/global/LSearch'),
    LPartner: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPartner'),
    LUserShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LUserShadow'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
  },
  created () {
  },
  data () {
    return {
      preloader: true,
      partners: [],
      partnerCursor: null,
      partnerId: 1,
    }
  },
  methods: {
    ...mapActions('posts', ['getPartners']),
    getPartnersList (loader) {
      this.feedfriends = []
      this.getPartners({ cursor: this.partnersCursor })
      .then(( response ) => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.partners.push(...response.data.data.data)
        console.log(this.partners)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.partnersCursor = response.data.data.meta.cursor
        }
      })
    },
    
  }
}
</script>

<style lang="scss">
.profile-partners {
  padding-top: 180px;
  padding-left: 38px;
  & .ltabs__header {
    justify-content: flex-start;
    &-item {
      margin-left: 40px;
      &:first-child {
        margin-left: 0px;
      }
    }
  }
  & .ltabs__content {
    padding-top: 20px;
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }
  &__preloader {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }
  &__search {
    margin-top: 30px;
    width: 40%;
  }
}
</style>