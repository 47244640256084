<template lang="pug">
.profile-followers
  LTabs
    LTab(title="Подписчики" :selected="true")
      .profile-followers__search
        LSearch

      .profile-followers__list
        LUser(
          v-for="user in followers"
          :username="user.name"
          :uuid="user.uuid"
          :photo="user.photo.preview"
          :isadv="user.type === 'user' ? false : true"
        )
      <infinite-loading @infinite="getFollowersList" :identifier="followersId">
        template(slot="spinner")
          .profile-followers__preloader
            LUserShadow(v-for="i in 10")

        template(slot="no-more")
          p

        template(slot="no-results")
          p

      </infinite-loading>


    LTab(title="Посты от подписчиков")
      div(style="height: 30px;")
      LCard(
        v-for="item in feedFollowers"
        :data="item"
      )
      <infinite-loading @infinite="getPostsFromFollowers" :identifier="postsId">
        template(slot="spinner")
          LCardShadow

        template(slot="no-more")
          p

        template(slot="no-results")
          p

      </infinite-loading>


</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileFollowers',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LSearch: () => import(/* webpackChunkName: "global" */ '@/components/global/LSearch'),
    LUser: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LUser'),
    LUserShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LUserShadow'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
  },
  created () {
  },
  data () {
    return {
      preloader: true,
      followers: [],
      followersCursor: null,
      feedFollowers: [],
      feedFollowersCursor: null,
      followersId: 1,
      postsId: 10000
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS', 'USER_DATA']),
  },
  methods: {
    ...mapActions('posts', ['getFeedFollowers', 'getFollowers']),
    getFollowersList (loader) {
      this.feedFollowers = []
      this.getFollowers({ cursor: this.followersCursor })
      .then(( response ) => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.followers.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          console.log(response.data.data.meta.cursor)
          this.followersCursor = response.data.data.meta.cursor
        }
      })
    },
    getPostsFromFollowers (loader) {
      this.followers = []
      this.getFeedFollowers({ cursor: this.feedFollowersCursor })
      .then(( response ) => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.feedFollowers.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.feedFollowersCursor = response.data.data.meta.cursor
        }
      })
    }

  }
}
</script>

<style lang="scss">
.profile-followers {
  padding-top: 110px;
  padding-left: 38px;
  & .ltabs__header {
    justify-content: flex-start;
    &-item {
      margin-left: 40px;
      &:first-child {
        margin-left: 0px;
      }
    }
  }
  & .ltabs__content {
    padding-top: 20px;
  }
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }
  &__preloader {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }
  &__search {
    margin-top: 30px;
    width: 40%;
  }
}
</style>