<template lang="pug">
.profile-statistic
  .profile-statistic__filters
    .profile-statistic__filters-item(
      :class="[{'profile-statistic__filters-item_active': filterType === 'day'}]"
      @click="setFilter('day')"
    ) ЗА ДЕНЬ
    .profile-statistic__filters-item(
      :class="[{'profile-statistic__filters-item_active': filterType === 'month'}]"
      @click="setFilter('month')"
    ) ЗА МЕСЯЦ
    .profile-statistic__filters-item(
      :class="[{'profile-statistic__filters-item_active': filterType === 'year'}]"
      @click="setFilter('year')"
    ) ЗА ГОД

  .profile-statistic__content
    LStatisticBlock(@opened="")
      template(#title) Статистика по заработку
      template(#list)
        .lstatistic-block__item(v-for="earning in earningsList")
          .lstatistic-block__item-preview
            .lstatistic-block__item-preview-video(v-if="earning.media.length > 0 && earning.media.type !== 'image'")
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1427_20)">
                  <path opacity="0.2" d="M35.3491 35.3617C43.1453 27.5631 43.1453 14.9192 35.3491 7.12058C27.5528 -0.677999 14.9126 -0.678 7.11636 7.12058C-0.679889 14.9192 -0.679889 27.5631 7.11636 35.3617C14.9126 43.1603 27.5528 43.1603 35.3491 35.3617Z" fill="white"/>
                  <path d="M31.4773 21.3765L16 11C16 26.68 16 18.9937 16 31.8298L31.4773 21.3765Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1427_20">
                    <rect width="42" height="42" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            img(:src="earning.media[0].preview" v-if="!!earning.media && earning.media.length > 0")
            span(v-else) медиа отсутствуют

          .lstatistic-block__item-amount {{ earning.amount.value }}
            span {{ earning.amount.currency }}

        <infinite-loading @infinite="getEarningsList" :identifier="earningsId">
          template(slot="spinner")
            .lstatistic-block__item
              .lstatistic-block__image(style="background: #FAF9F9; width: 160px; height: 90px;")
              .lstatistic-block__amount(style="background: #FAF9F9; width: 100px; height: 30px;")

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>
      template(#total)
        .lstatistic-block__item-total(v-if="!!earningSummary") Общая сумма 
          .lstatistic-block__item-total-sum {{ earningSummary.value }} 
            span {{ earningSummary.currency }}

    LStatisticBlock(@opened="")
      template(#title) Статистика по популярности продукта
      template(#list)
        .lstatistic-block__item(v-for="item in popularyList")
          .lstatistic-block__item-preview
            .lstatistic-block__item-preview-video(v-if="item.media.length > 0 && item.media.type !== 'image'")
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1427_20)">
                  <path opacity="0.2" d="M35.3491 35.3617C43.1453 27.5631 43.1453 14.9192 35.3491 7.12058C27.5528 -0.677999 14.9126 -0.678 7.11636 7.12058C-0.679889 14.9192 -0.679889 27.5631 7.11636 35.3617C14.9126 43.1603 27.5528 43.1603 35.3491 35.3617Z" fill="white"/>
                  <path d="M31.4773 21.3765L16 11C16 26.68 16 18.9937 16 31.8298L31.4773 21.3765Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1427_20">
                    <rect width="42" height="42" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            img(:src="item.media[0].preview" v-if="!!item.media && item.media.length > 0")
            span(v-else) медиа отсутствуют

          .lstatistic-block__item-stat 
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.6657 40.3313C31.1955 40.3313 39.7317 31.7952 39.7317 21.2653C39.7317 10.7354 31.1955 2.19922 20.6657 2.19922C10.1358 2.19922 1.59961 10.7354 1.59961 21.2653C1.59961 31.7952 10.1358 40.3313 20.6657 40.3313Z" fill="#C31A14"/>
              <path d="M20.1053 16.7781C18.9145 14.5346 17.7228 13.4141 15.3383 13.4141C12.7072 13.4141 10.5723 15.4234 10.5723 17.8997C10.5723 22.3863 15.3383 26.8726 20.1053 31.3586C24.8723 26.8726 29.6383 22.3863 29.6383 17.8997C29.6383 15.4234 27.5034 13.4141 24.8723 13.4141C22.4878 13.4141 21.2961 14.5346 20.1053 16.7781Z" fill="#FEFEFE"/>
            </svg>
            span {{ item.likes }}

          .lstatistic-block__item-stat
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.8668 40.3313C31.3967 40.3313 39.9329 31.7952 39.9329 21.2653C39.9329 10.7354 31.3967 2.19922 20.8668 2.19922C10.3369 2.19922 1.80078 10.7354 1.80078 21.2653C1.80078 31.7952 10.3369 40.3313 20.8668 40.3313Z" fill="#757373"/>
              <path d="M31.9562 12H10.0135C9.45453 12 9 12.4439 9 12.9918V28.0671C9 28.6141 9.45363 29.0589 10.0135 29.0589H22.1788L26.8447 32.4701C27.4748 32.9308 27.9896 32.678 27.9896 31.9082V29.058H31.9553C32.5142 29.058 32.9688 28.6141 32.9688 28.0662V12.9909C32.9688 12.4439 32.5151 12 31.9562 12ZM15.4571 21.9448C14.5912 21.9448 13.8901 21.2578 13.8901 20.4114C13.8901 19.5641 14.5921 18.878 15.4571 18.878C16.322 18.878 17.0241 19.565 17.0241 20.4114C17.0232 21.2578 16.322 21.9448 15.4571 21.9448ZM20.9853 21.9448C20.1194 21.9448 19.4183 21.2578 19.4183 20.4114C19.4183 19.5641 20.1203 18.878 20.9853 18.878C21.8502 18.878 22.5523 19.565 22.5523 20.4114C22.5523 21.2578 21.8511 21.9448 20.9853 21.9448ZM26.5135 21.9448C25.6476 21.9448 24.9465 21.2578 24.9465 20.4114C24.9465 19.5641 25.6485 18.878 26.5135 18.878C27.3784 18.878 28.0805 19.565 28.0805 20.4114C28.0805 21.2578 27.3793 21.9448 26.5135 21.9448Z" fill="white"/>
            </svg>
            span {{ item.comments }}

          .lstatistic-block__item-stat
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1540_2)">
              <path d="M21.3508 41C32.3077 41 41.19 32.2696 41.19 21.5C41.19 10.7304 32.3077 2 21.3508 2C10.394 2 1.51172 10.7304 1.51172 21.5C1.51172 32.2696 10.394 41 21.3508 41Z" fill="#3E8CC7"/>
              <path d="M21.3514 31.3506C18.2495 31.3506 15.1953 30.4138 12.5193 28.6414C9.92364 26.9221 7.72529 24.4532 6.16211 21.5014C7.72537 18.5496 9.92364 16.0807 12.5193 14.3615C15.1953 12.5892 18.2494 11.6523 21.3514 11.6523C24.4535 11.6523 27.5076 12.5892 30.1836 14.3615C32.7792 16.0807 34.9775 18.5496 36.5408 21.5014C34.9775 24.4532 32.7792 26.9221 30.1836 28.6413C27.5075 30.4138 24.4534 31.3506 21.3514 31.3506V31.3506Z" fill="white"/>
              <path d="M21.3517 29.5108C25.8521 29.5108 29.5003 25.9249 29.5003 21.5015C29.5003 17.0781 25.8521 13.4922 21.3517 13.4922C16.8514 13.4922 13.2031 17.0781 13.2031 21.5015C13.2031 25.9249 16.8514 29.5108 21.3517 29.5108Z" fill="#3E8CC7"/>
              <path d="M21.3519 26.0797C23.9248 26.0797 26.0105 24.0297 26.0105 21.5008C26.0105 18.9719 23.9248 16.9219 21.3519 16.9219C18.7791 16.9219 16.6934 18.9719 16.6934 21.5008C16.6934 24.0297 18.7791 26.0797 21.3519 26.0797Z" fill="#414952"/>
              </g>
              <defs>
              <clipPath id="clip0_1540_2">
              <rect width="42" height="42" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            span {{ item.views }}

          .lstatistic-block__item-stat
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.2653 40.3313C31.7952 40.3313 40.3313 31.7952 40.3313 21.2653C40.3313 10.7354 31.7952 2.19922 21.2653 2.19922C10.7354 2.19922 2.19922 10.7354 2.19922 21.2653C2.19922 31.7952 10.7354 40.3313 21.2653 40.3313Z" fill="#0A156A"/>
              <path d="M18.0698 17.1854C9.26387 17.0789 4.85997 22.0352 3.94531 29.2577C8.55431 22.2373 15.9806 22.5627 18.1258 23.4052L18.2685 32.6629L29.5617 20.5066L17.9267 7.25L18.0698 17.1854Z" fill="#FEFEFE"/>
            </svg>
            span {{ item.shared }}

        <infinite-loading @infinite="getPopularyList" :identifier="popularyId">
          template(slot="spinner")
            .lstatistic-block__item
              .lstatistic-block__image(style="background: #FAF9F9; width: 160px; height: 90px;")
              .lstatistic-block__amount(style="background: #FAF9F9; width: 100px; height: 30px;")

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>

    LStatisticBlock(@opened="")
      template(#title) Статистика по количеству показов
      template(#list)
        .lstatistic-block__item(v-for="item in screeningsList")
          .lstatistic-block__item-preview
            .lstatistic-block__item-preview-video(v-if="!!item.media && item.media.length > 0 && item.media.type !== 'image'")
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1427_20)">
                  <path opacity="0.2" d="M35.3491 35.3617C43.1453 27.5631 43.1453 14.9192 35.3491 7.12058C27.5528 -0.677999 14.9126 -0.678 7.11636 7.12058C-0.679889 14.9192 -0.679889 27.5631 7.11636 35.3617C14.9126 43.1603 27.5528 43.1603 35.3491 35.3617Z" fill="white"/>
                  <path d="M31.4773 21.3765L16 11C16 26.68 16 18.9937 16 31.8298L31.4773 21.3765Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1427_20">
                    <rect width="42" height="42" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            img(:src="item.media[0].preview" v-if="!!item.media && item.media.length > 0")
            span(v-else) медиа отсутствуют

          .lstatistic-block__item-amount {{ item.views }}

        <infinite-loading @infinite="getScreeningsList" :identifier="screeningsId">
          template(slot="spinner")
            .lstatistic-block__item
              .lstatistic-block__image(style="background: #FAF9F9; width: 160px; height: 90px;")
              .lstatistic-block__amount(style="background: #FAF9F9; width: 100px; height: 30px;")

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>
      template(#total)
        .lstatistic-block__item-total(v-if="!!earningSummary") Общие показы
          .lstatistic-block__item-total-sum {{ screeningsSummary }}
            span раз

    LStatisticBlock(@opened="")
      template(#title) Статистика по количеству просмотров
      template(#list)
        .lstatistic-block__item(v-for="item in viewsList")
          .lstatistic-block__item-preview
            .lstatistic-block__item-preview-video(v-if="!!item.media && item.media.length > 0 && item.media.type !== 'image'")
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1427_20)">
                  <path opacity="0.2" d="M35.3491 35.3617C43.1453 27.5631 43.1453 14.9192 35.3491 7.12058C27.5528 -0.677999 14.9126 -0.678 7.11636 7.12058C-0.679889 14.9192 -0.679889 27.5631 7.11636 35.3617C14.9126 43.1603 27.5528 43.1603 35.3491 35.3617Z" fill="white"/>
                  <path d="M31.4773 21.3765L16 11C16 26.68 16 18.9937 16 31.8298L31.4773 21.3765Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1427_20">
                    <rect width="42" height="42" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            img(:src="item.media[0].preview" v-if="!!item.media && item.media.length > 0")
            span(v-else) медиа отсутствуют

          .lstatistic-block__item-amount {{ item.views }}

        <infinite-loading @infinite="getViewsList" :identifier="viewsId">
          template(slot="spinner")
            .lstatistic-block__item
              .lstatistic-block__image(style="background: #FAF9F9; width: 160px; height: 90px;")
              .lstatistic-block__amount(style="background: #FAF9F9; width: 100px; height: 30px;")

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>
      template(#total)
        .lstatistic-block__item-total(v-if="!!earningSummary") Общие просмотры
          .lstatistic-block__item-total-sum {{ viewsSummary }}
            span раз

    LStatisticBlock(@opened="")
      template(#title) Тематика/По категориям
      template(#list)
        .lstatistic-block__filters
          LDropdown(placeholder="Цифровые материалы")
            span(slot="value" v-if="!!themesModel") {{ themesModel.name }}
            template(slot="items")
              .signup__userrole-item(
                v-for="item in themesModelList"
                @click="setTheme({ 'model': 'themes', 'data': item })"
              ) {{ $t(`${item.name}`) }}

          LDropdown(placeholder="Материальные ролики")
            span(slot="value" v-if="!!categoriesModel") {{ categoriesModel.name }}
            template(slot="items")
              .signup__userrole-item(
                v-for="item in categoriesModelList"
                @click="setTheme({ 'model': 'categories', 'data': item })"
              ) {{ $t(`${item.name}`) }}

        .lstatistic-block__item(v-for="item in themesList")
          .lstatistic-block__item-preview
            .lstatistic-block__item-preview-video(v-if="!!item.media && item.media.length > 0 && item.media.type !== 'image'")
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1427_20)">
                  <path opacity="0.2" d="M35.3491 35.3617C43.1453 27.5631 43.1453 14.9192 35.3491 7.12058C27.5528 -0.677999 14.9126 -0.678 7.11636 7.12058C-0.679889 14.9192 -0.679889 27.5631 7.11636 35.3617C14.9126 43.1603 27.5528 43.1603 35.3491 35.3617Z" fill="white"/>
                  <path d="M31.4773 21.3765L16 11C16 26.68 16 18.9937 16 31.8298L31.4773 21.3765Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1427_20">
                    <rect width="42" height="42" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            img(:src="item.media[0].preview" v-if="!!item.media && item.media.length > 0")
            span(v-else) медиа отсутствуют

          .lstatistic-block__item-amount {{ item.views }}

        <infinite-loading @infinite="getThemesList" :identifier="themesId">
          template(slot="spinner")
            .lstatistic-block__item
              .lstatistic-block__image(style="background: #FAF9F9; width: 160px; height: 90px;")
              .lstatistic-block__amount(style="background: #FAF9F9; width: 100px; height: 30px;")

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileMyAdvert',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
    LStatisticBlock: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LStatisticBlock'),
    LDropdown: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LDropdown'),
  },
  created () {
    this.getCategories()
  },
  data () {
    return {
      earningsList: [],
      earningsId: 1,
      earningsCursor: null,
      earningSummary: null,

      popularyList: [],
      popularyId: 1,
      popularyCursor: null,

      screeningsList: [],
      screeningsId: 1,
      screeningsCursor: null,
      screeningsSummary: null,

      viewsList: [],
      viewsId: 1,
      viewsCursor: null,
      viewsSummary: null,
      filter: null,
      filterType: 'day',

      themesList: [],
      themesId: 1,
      themesCursor: null,

      themesModel: null,
      categoriesModel: null,
      themesModelList: [],
      categoriesModelList: []

    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS', 'USER_DATA']),
    getCategoryName () {
      if (this.themesModel || this.categoriesModel) {

        if(this.themesModel !== null) return this.themesModel.slug
        if(this.categoriesModel !== null) return this.categoriesModel.slug

      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('user', [
      'settings',
      'getEarnings',
      'getScreenings',
      'getEarningsSummary',
      'getScreeningsSummary',
      'getViews',
      'getViewsSummary',
      'getThemes',
      'getPopulary'
    ]),
    ...mapActions('dictonary', [
        'categories'
    ]),
    setTheme ( item ) {
      if (item.model === 'themes') {
        this.categoriesModel = null
        this.themesModel = item.data
      }
      if (item.model === 'categories') {
        this.categoriesModel = item.data
        this.themesModel = null
      }
      this.themesList = []
      this.themesCursor = null
      this.themesId += 1
    },
    getCategories () {
      this.categories()
      .then(res => {
        console.log('cats', res)
        let dt = res.data.data.data
        dt.forEach(item => {
          if (item.slug === 'digital_products') this.themesModelList = item.categories
          if (item.slug === 'material_products') this.categoriesModelList = item.categories
        })
      })
    },
    setFilter (filter) {
      let now = Date.now()
      let day, month, year
      let d = new Date(now)
      this.filterType = filter
      if (filter === 'day') {
        
        day = d.getDate()
        day = day.toString().length > 1 ? day : `0${day}`
        year = d.getFullYear()
        month = d.getMonth() + 1
        month = month.toString().length > 1 ? month : `0${month}`

      }

      if (filter === 'month') {
        day = '01'
        year = d.getFullYear()
        month = d.getMonth() + 1
        month = month.toString().length > 1 ? month : `0${month}`

      }

      if (filter === 'year') {
        day = '01'
        year = d.getFullYear()
        month = '01'

      }
      this.filter = `${year}-${month}-${day}`

      this.earningsList = []
      this.earningsId += 1
      this.earningsCursor = null

      this.screeningsList = []
      this.screeningsId += 1
      this.screeningsCursor = null

      this.viewsList = []
      this.viewsId += 1
      this.viewsCursor = null

      this.themesList = []
      this.themesId += 1
      this.themesCursor = null

      this.themesModel = null
      this.categoriesModel = null

      this.popularyList = []
      this.popularyId += 1
      this.popularyCursor = null

    },
    getEarningsList (loader) {
      this.getEarningsSummary({ filter: this.filter }).then(res => {
        this.earningSummary = res.data.data
      })
      this.getEarnings({ cursor: this.earningsCursor, filter: this.filter })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.earningsList.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.earningsCursor = response.data.data.meta.cursor
        }
      })
    },

    getPopularyList (loader) {
      this.getPopulary({ cursor: this.popularyCursor, filter: this.filter })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.popularyList.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.popularyCursor = response.data.data.meta.cursor
        }
      })
    },
    getScreeningsList (loader) {
      this.getScreeningsSummary({ filter: this.filter }).then(res => {
        this.screeningsSummary = res.data.data
      })
      this.getScreenings({ cursor: this.screeningsCursor, filter: this.filter })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.screeningsList.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.screeningsCursor = response.data.data.meta.cursor
        }
      })
    },
    getViewsList (loader) {
      if (!this.viewsSummary) {
        this.getViewsSummary({ filter: this.filter }).then(res => {
          this.viewsSummary = res.data.data
        })
      }
      this.getViews({ cursor: this.viewsCursor, filter: this.filter })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        console.log(response)
        this.viewsList.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.viewsCursor = response.data.data.meta.cursor
        }
      })
    },
    getThemesList(loader) {
      this.getThemes({ cursor: this.themesCursor, filter: this.filter, category: this.getCategoryName })
      .then(response => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.themesList.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.themesCursor = response.data.data.meta.cursor
        }
      })
    }
  }
}
</script>

<style lang="scss">
.lstatistic-block {
  margin-bottom: 15px;
  &__filters {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    & .ldropdown {
      width: 48%;
      & .ldropdown__selected {
        border: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-stat {
      display: flex;
      align-items: center;
      color: #757373;
      & svg {
        margin-right: 15px;
      }
    }
    &-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      &-sum {
        font-size: 35px;
        & > span {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    &-preview {
      width: 160px;
      height: 90px;
      background: #FAF9F9;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #C4C4C4;
      & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &-video {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        & > svg {
          width: 70px;
          height: 70px;
        }
      }
    }
    &-amount {
      font-weight: bold;
      font-size: 24px;
      & > span {
        font-weight: 500;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
}
.profile-statistic {
  padding-top: 110px;
  padding-left: 38px;
  &__filters {
    display: flex;
    max-width: 40%;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 50px;
    &-item {
      cursor: pointer;
      &_active {
        font-weight: 700;
        position: relative;
        &:after {
          position: absolute;
          bottom: 0;
          top: 22px;
          left: 0;
          right: 0;
          height: 3px;
          width: 100%;
          content: '';
          background: #C31A14;
          opacity: .6;
        }
      }
    }
  }
  & .ltabs__header {
    width: 40%;
  }
  & .ltabs__content {
    padding-top: 50px;
  }
  
}
@keyframes blick {
 50% {
  opacity: .6;
 }
}
</style>