<template lang="pug">
.profile-balance
  .profile-balance__header
    .profile-balance__header-balance(@click="balancePopup = true")
      .profile-balance__header-balance-item 0
      .profile-balance__header-balance-text

    //- LDropdown

  .profile-balance__content
    .profile-balance__orders
      .profile-balance__orders-item(v-if="earnings !== null")
        h3 Заработок за сегодня
        p Партнёрское вознаграждение
          span {{ earnings.partner_reward.value }} руб.

        p Вознаграждение "Лайк-системы"
          span {{ earnings.like_reward.value }} руб.

        p Приведи друга
          span {{ earnings.referal.value }} руб.

        h4 Общая сумма
          span Руб. ------ {{ totalToday }} р.

      .profile-balance__orders-preloader(v-if="earnings === null")


      .profile-balance__orders-item(v-if="processing !== null")
        h3 В обработке
        p Партнёрское вознаграждение
          span {{ processing.partner_reward.value }} руб.

        p Вознаграждение "Лайк-системы"
          span {{ processing.like_reward.value }} руб.

        p Приведи друга
          span {{ processing.referal.value }} руб.

        h4 Общая сумма
          span Руб. ------ {{ totalToday }} р.

      .profile-balance__orders-preloader(v-if="processing === null")

      .profile-balance__orders-item(v-if="ready !== null")
        h3 Готовы к снятию
        p Партнёрское вознаграждение
          span {{ ready.partner_reward.value }} руб.

        p Вознаграждение "Лайк-системы"
          span {{ ready.like_reward.value }} руб.

        p Приведи друга
          span {{ ready.referal.value }} руб.

        h4 Общая сумма
          span Руб. ------ {{ totalToday }} р.

      .profile-balance__orders-preloader(v-if="ready === null")

    .profile-balance__nav
      LButton(size="small" theme="blue") Вывести деньги
      LButton.mt-1(size="small" theme="blue") Заплатить
      LButton.mt-1(size="small" theme="blue" @click="historyPaymentsPopup = true") История выплат
      LButton.mt-1(size="small" theme="blue" @click="historyPaymentsPopup = true") История переводов

  LPopup(
    v-if="balancePopup"
    @close="balancePopup = false"
    :class="{ 'profile-balance__balance-popup': balancePopup }"
  )
      h3 Готовы к снятию
      .profile-balance__balance-popup-total.mt-5.mb-5 23093
        span руб.

      LButton Вывести деньги
      LButton.mt-2(theme="blue" @click="toHistiryPayments") История выплат
      LButton.mt-2(theme="blue" @click="balancePopup = false") закрыть

  LPopup(
    v-if="historyPaymentsPopup"
    @close="historyPaymentsPopup = false"
    :class="{ 'profile-balance__histiry-payments-popup': historyPaymentsPopup }"
  )
      .histiry-payments__table
        .histiry-payments__table-header
          .histiry-payments__table-header-item ID
          .histiry-payments__table-header-item Дата
          .histiry-payments__table-header-item Статус
          .histiry-payments__table-header-item Исходная сумма
          .histiry-payments__table-header-item Перечислено
          .histiry-payments__table-header-item Способ оплаты
          .histiry-payments__table-header-item Инвойс
          .histiry-payments__table-header-item Выписка
          .histiry-payments__table-header-item Комментарии

        .histiry-payments__table-body
          .histiry-payments__table-body-item
            .histiry-payments__table-body-item-col 54346
            .histiry-payments__table-body-item-col 10.02.22
            .histiry-payments__table-body-item-col ожидает
            .histiry-payments__table-body-item-col 582.00 руб
            .histiry-payments__table-body-item-col 582.00 руб
            .histiry-payments__table-body-item-col Банковская карта
            .histiry-payments__table-body-item-col CSKLF-4420
            .histiry-payments__table-body-item-col
              <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0855 1H1.532C1.23818 1 1 1.24298 1 1.71448V21.4032C1 21.5312 1.23818 21.7742 1.532 21.7742H16.1044C16.3982 21.7742 16.6364 21.5312 16.6364 21.4032V5.81442C16.6364 5.55623 16.6025 5.47313 16.5429 5.41192L12.3116 1.09534C12.2516 1.0345 12.1702 1 12.0855 1Z" fill="#E9E9E0"/>
                <path d="M12.2727 1.05469V5.45028H16.5814L12.2727 1.05469Z" fill="#D9D7CA"/>
                <path d="M5.73256 13.362C5.60601 13.362 5.48456 13.3201 5.38092 13.241C5.00238 12.9513 4.95147 12.629 4.97547 12.4093C5.04165 11.8054 5.77365 11.1733 7.15183 10.5293C7.69874 9.30656 8.2191 7.80006 8.52929 6.54137C8.16638 5.73563 7.81365 4.69024 8.07074 4.07703C8.16092 3.86224 8.27328 3.69753 8.4831 3.62631C8.56601 3.59811 8.77547 3.5625 8.85256 3.5625C9.03583 3.5625 9.19692 3.80326 9.3111 3.95165C9.41838 4.09113 9.66165 4.38679 9.17547 6.47497C9.66565 7.50774 10.3602 8.55981 11.0256 9.28023C11.5024 9.19231 11.9126 9.14742 12.2467 9.14742C12.8162 9.14742 13.1613 9.28282 13.302 9.56179C13.4184 9.79253 13.3707 10.0622 13.1602 10.3631C12.9576 10.6521 12.6784 10.8049 12.3529 10.8049C11.9107 10.8049 11.3958 10.52 10.8216 9.95724C9.79001 10.1772 8.58529 10.5697 7.61147 11.0041C7.30747 11.6622 7.01619 12.1923 6.74492 12.5811C6.37219 13.1134 6.05074 13.362 5.73256 13.362ZM6.70056 11.4604C5.92347 11.9059 5.60674 12.2721 5.58383 12.4783C5.58019 12.5125 5.57038 12.6022 5.74056 12.735C5.79474 12.7176 6.1111 12.5703 6.70056 11.4604ZM11.6595 9.81256C11.9558 10.0452 12.0282 10.1628 12.222 10.1628C12.3071 10.1628 12.5496 10.159 12.662 9.99916C12.7162 9.92163 12.7373 9.87192 12.7456 9.84521C12.7009 9.8211 12.6416 9.77213 12.3184 9.77213C12.1347 9.7725 11.9038 9.78066 11.6595 9.81256ZM8.9431 7.37086C8.6831 8.28863 8.33983 9.27949 7.97074 10.1769C8.73074 9.876 9.55692 9.61336 10.3329 9.4275C9.84201 8.84582 9.35147 8.11947 8.9431 7.37086ZM8.72238 4.23173C8.68674 4.24397 8.23874 4.88352 8.75729 5.42476C9.10238 4.64016 8.73801 4.22653 8.72238 4.23173Z" fill="#CC4B4C"/>
                <path d="M16.1044 21.7752H1.532C1.23818 21.7752 1 21.5322 1 21.2325V15.4688H16.6364V21.2325C16.6364 21.5322 16.3982 21.7752 16.1044 21.7752Z" fill="#CC4B4C"/>
                <path d="M4.9583 20.6597H4.36157V16.9219H5.41539C5.57103 16.9219 5.72521 16.9471 5.87757 16.9979C6.02993 17.0487 6.16666 17.1248 6.28775 17.2261C6.40884 17.3273 6.50666 17.4501 6.58121 17.5937C6.65575 17.7373 6.69321 17.8986 6.69321 18.0782C6.69321 18.2677 6.66157 18.4391 6.59866 18.5931C6.53575 18.747 6.44775 18.8765 6.33503 18.9811C6.2223 19.0857 6.0863 19.167 5.92739 19.2245C5.76848 19.282 5.59248 19.3105 5.40048 19.3105H4.95794V20.6597H4.9583ZM4.9583 17.3834V18.8643H5.50521C5.57793 18.8643 5.64993 18.8516 5.72157 18.8261C5.79284 18.8008 5.8583 18.7593 5.91793 18.7018C5.97757 18.6443 6.02557 18.5641 6.06193 18.461C6.0983 18.3579 6.11648 18.2303 6.11648 18.0782C6.11648 18.0173 6.10812 17.9469 6.09175 17.8678C6.07503 17.7885 6.04121 17.7124 5.98993 17.6397C5.9383 17.567 5.8663 17.5061 5.77357 17.4572C5.68084 17.4082 5.55793 17.3837 5.40557 17.3837H4.9583V17.3834Z" fill="white"/>
                <path d="M10.3525 18.6869C10.3525 18.9945 10.3201 19.2575 10.2554 19.4756C10.1907 19.6937 10.1088 19.8763 10.0092 20.0235C9.90956 20.1708 9.79792 20.2866 9.67356 20.3711C9.5492 20.4557 9.4292 20.5188 9.3132 20.5614C9.1972 20.6037 9.09101 20.6308 8.99501 20.6427C8.89901 20.6538 8.82774 20.6597 8.7812 20.6597H7.39429V16.9219H8.49792C8.80629 16.9219 9.0772 16.972 9.31065 17.0714C9.54411 17.1708 9.73829 17.304 9.89247 17.4694C10.0467 17.6349 10.1616 17.8237 10.2379 18.0348C10.3143 18.2462 10.3525 18.4636 10.3525 18.6869ZM8.58265 20.2135C8.98701 20.2135 9.27865 20.0818 9.45756 19.818C9.63647 19.5543 9.72592 19.1722 9.72592 18.6717C9.72592 18.5163 9.70774 18.3623 9.67138 18.2102C9.63465 18.0581 9.56447 17.9205 9.46011 17.797C9.35574 17.6735 9.21392 17.5737 9.03501 17.4976C8.85611 17.4216 8.62411 17.3834 8.33901 17.3834H7.99101V20.2135H8.58265V20.2135Z" fill="white"/>
                <path d="M11.8243 17.3834V18.5601H13.3556V18.9759H11.8243V20.6597H11.2178V16.9219H13.5094V17.3834H11.8243Z" fill="white"/>
              </svg>

              .histiry-payments__table-body-item-data.ml-1
                span Кратко
                span(@click="detailsTransactionPopup = true") Детально

              LPopup.details-transaction__popup(
                v-if="detailsTransactionPopup"
                @close="detailsTransactionPopup = false"
              )
                h3.mb-3 Детально
                LButton.mt-2 Скачать
                LButton.mt-2 Печатать
                LButton.mt-2(theme="blue") Закрыть

            .histiry-payments__table-body-item-col Ожидаемый день выплаты 21.02.22

      LButton.mt-2.mx-a(theme="blue" @click="historyPaymentsPopup = false" style="max-width: 320px;") закрыть

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileBalance',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LDropdown: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LDropdown'),
    LPopup: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LPopup')
  },
  async created () {
    this.getBalance()
    this.getBalanceEarnings()
  },
  data () {
    return {
      balance: null,
      earnings: null,
      ready: null,
      processing: null,

      balancePopup: false,
      historyPaymentsPopup: false,
      detailsTransactionPopup: false

    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS', 'USER_DATA']),
    totalToday () {
      if (this.earnings !== null) {
        return this.earnings.partner_reward.value + this.earnings.like_reward.value + this.earnings.referal.value
      }
      return 0
    }
  },
  methods: {
    ...mapActions('user', ['getUserBalance', 'getUserBalanceEarnings']),
    toHistiryPayments () {
      this.historyPaymentsPopup = true
      this.balancePopup = false
    },
    getBalance () {
      this.getUserBalance().then( res => {
        console.log(res)
        this.balance = res.data.data.data
      })
    },
    getBalanceEarnings () {
      this.getUserBalanceEarnings()
      .then(res => {
        this.earnings = res.data.data.data
        this.ready = res.data.data.data
        this.processing = res.data.data.data
      })
    }
  }
}
</script>

<style lang="scss">
.details-transaction__popup {
  & .lpopup__body {
    width: 480px;
    min-width: 480px !important;
  }
}
.histiry-payments {
  &__table {
    background-color: #fff;
    padding: 10px;
    &-header {
      display: grid;
      grid-template-columns: 60px 60px 80px 80px 80px 110px 80px 100px 200px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 0 5px;
      }
    }
    &-body {
      &-item {
        
        display: grid;
        grid-template-columns: 60px 60px 80px 80px 80px 110px 80px 100px 200px;
        font-size: 12px;
        font-weight: bold;
        &-col {
          background-color: #FAF9F9;
          margin: 4px 2px;
          text-align: center;
          padding: 5px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }
        &-data {
          display: flex;
          flex-direction: column;
          text-align: left;
          & span {
            font-size: 10px;
            &:last-child {
              color: #0C2C54;
              text-decoration: underline;
              padding-top: 2px;
            }
          }
        }
      }
    }
  }
}
.profile-balance__histiry-payments-popup {
  & .lpopup__body {
    max-width: 100%;
    min-width: 1020px;
  }
}


.profile-balance__balance-popup {
  &-total {
    font-weight: bold;
    font-size: 64px;
    text-align: center;
    & span {
      font-size: 16px;
    }
  }
  & .lpopup__body {
    max-width: 100%;
    width: 450px;
    & h3 {
      text-align: center;
    }
  }
}

.profile-balance {
  &__header {
    display: flex;
    height: 210px;
    align-items: center;
    width: 100%;
    &-balance {
      margin: 0 auto;
      cursor: pointer;
    }
    &-balance-item {
      width: 352px;
      height: 106px;
      border-radius: 10px;
      background-color: #C91818;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: 700;
      
    }
  }
  &__content {
    width: 100%;
    padding-left: 38px;
    display: flex;
  }
  &__orders {
    width: calc(100% - 270px);
    &-preloader {
      background: #c4c4c4;
      height: 274px;
      border-radius: 10px;
      margin-right: 38px;
      margin-bottom: 30px;
      animation: blick 1s ease-in-out infinite;
    }
    &-item {
      background: #fff;
      margin-right: 38px;
      margin-bottom: 30px;
      & > h4 {
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > span {
          display: inline-block;
          margin-left: auto;
        }
      }

      & > h3 {
        padding: 30px;
      }

      & > p {
        padding: 0 30px;
        margin-bottom: 10px;
        height: 30px;
        display: flex;
        align-items: center;
        background-color: #FAF9F9;
        & > span {
          display: inline-block;
          margin-left: auto;
        }
      }
    }
  }
  &__nav {
    width: 270px;
  }
}
@keyframes blick {
 50% {
  opacity: .6;
 }
}
</style>