<template lang="pug">
.category
  .home__wrapper
    .home__sidebar

      LCategories

      LFilters(
        @change="changeFilters"
        :filters="GET_FILTERS"
      )

    .category__feed(:key="rerender")
        .category__nav
          .category__nav-item(@click="$router.push('/app/')") {{ $t('Реклама') }}
          .category__nav-item(@click="$router.push('/app/followings')") {{ $t('Подписки') }}
          .category__nav-item(@click="$router.push('/app/all')") {{ $t('Общее') }}
        LCard(
          v-for="item, index in posts"
          :data="item"
          :key="index"
          @follow="toggleFollow"
        )
        <infinite-loading @infinite="getPosts" :identifier="postsId">
          template(slot="spinner")
            LCardShadow

          template(slot="no-more")
            p

          template(slot="no-results")
            p

        </infinite-loading>

    .home__banners

</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LAccordion: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAccordion'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
    LAutocomplite: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAutocomplite'),
    LFilters: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LFilters'),
    LCategories: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LCategories'),
  },
  created () {
    this.category = this.$route.params.slug
  },
  data () {
    return {
      posts: [],
      postsPagination: {},
      postsCursor: null,
      postsId: 0,
      category: null,
      rerender: 0

    }
  },
  computed: {
    ...mapGetters('posts', ['GET_FILTERS']),
    filters () {
      let filters = {}
      if (this.GET_FILTERS !== null && Object.keys(this.GET_FILTERS).length > 0) {
        let countries = []
        if (this.GET_FILTERS.countries !== undefined) {
          this.GET_FILTERS.countries.forEach(country => {
            countries.push(country.slug)
          })
          filters['countries'] = countries
        }
        if (this.GET_FILTERS.cities !== undefined) {
          let cities = []
          this.GET_FILTERS.cities.forEach(city => {
            cities.push(city.slug)
          })
          filters['cities'] = cities
        }
      } else {
        filters = null
      }
      return filters
    },
  },
  methods: {
    ...mapActions('posts', ['categoryPosts', 'setFilters']),
    getPosts (loader) {
      this.categoryPosts({ category: this.category, cursor: this.postsCursor, filters: this.filters })
      .then((response) => {
        if (response.status !== 200) {
          loader.complete()
          this.postsCursor = null
          return
        }
        this.posts.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
          this.postsCursor = null
        } else {
          loader.loaded()
          this.postsCursor = response.data.data.meta.cursor
        }
      })
    },
    toggleFollow (data) {
      this.posts.forEach((item, index) => {
        if (item.author.uuid === data.uuid) {
          let newItem = item
          newItem.author.is_follow = data.status
          this.posts.splice(index, 1, newItem)
        }
      })
    },
    changeFilters(filters) {
      this.setFilters({
        filters: filters,
        type: 'feed'
      })
      this.posts = []
      this.rerender += 1
    }
  },
  watch: {
    $route (newVal) {
      this.rerender += 1
      this.posts = []
      this.postsCursor = null
      this.category = newVal.params.slug
    }
  }
}
</script>

<style lang="scss">
.category {
  background: #FAF9F9;
  min-height: 100vh;
  &__nav {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 10px;
    &-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    max-width: 1170px;
    margin: 0 auto;
  }
  &__sidebar {
    width: 300px;
    padding-top: 100px;
  }
  &__feed {
    width: calc(100% - 440px);
    box-sizing: border-box;
    padding: 0 30px;
    padding-top: 40px;
    & .ltabs__header {
      width: 50%;
      margin-bottom: 10px;
    }
  }
}


.accordion {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 60px 30px;
  margin-bottom: 30px;
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 30px;
  }
  &__sub-item {
    font-size: 12px;
    margin-bottom: 15px;
  }
  &__header {
    cursor: pointer;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

</style>