<template lang="pug">
.profile-user-view
  LUserView(:uuid="uuid")
  LCard(
    v-for="item, index in posts"
    :data="item"
    :key="index"
    :canFollow="false"
  )
  <infinite-loading @infinite="getUserFeed" :identifier="feedId">
    template(slot="spinner")
      LCardShadow

    template(slot="no-more")
      p

    template(slot="no-result")
      p

  </infinite-loading>

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileFollows',
  components: {
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LUserView: () => import(/* webpackChunkName: "ui" */ '@/components/composite/LUserView'),
  },
  created () {
    this.uuid = this.$route.params.uuid
  },
  data () {
    return {
      feedPrealoder: true,
      userPreloader: true,
      currentPage: 1,
      feedId: 1,
      posts: [],
      uuid: null
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('posts', ['userFeed']),
    getUserFeed (loader) {
      this.userFeed({ page: this.currentPage, uuid: this.uuid })
      .then((response) => {
        this.posts.push(...response.data.data.data)
        let nextPage = this.currentPage + 1
        if (response.data.data.meta.count_pages < nextPage) {
          loader.complete()
        } else {
          loader.loaded()
          this.currentPage += 1
        }
      })
    }
  }
}
</script>

<style lang="scss">
.profile-user-view {
  padding-top: 40px;
  padding-left: 38px;
}
</style>