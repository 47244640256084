<template lang="pug">
.profile-my-posts
  LTabs.profile-my-posts__tabs
    LTab(:title="$t('Личные')" selected)
      LCard(
        v-for="item, index in personalPosts"
        :data="item"
        :key="index"
      )
      <infinite-loading @infinite="getPersonalPosts" :identifier="personalFeedId">
        template(slot="spinner")
          LCardShadow

        template(slot="no-more")
          p 

        template(slot="no-result")
          p 

      </infinite-loading>

    LTab(:title="$t('Рекламные')")
      LCard(
        v-for="item, index in advertPosts"
        :data="item"
        :key="index"
      )
      <infinite-loading @infinite="getAdvertPosts" :identifier="advertFeedId">
        template(slot="spinner")
          LCardShadow

        template(slot="no-more")
          p 

        template(slot="no-result")
          p 

      </infinite-loading>



</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileMyPosts',
  components: {
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow')
  },
  data () {
    return {
      personalPosts: [],
      personalCursor: null,
      personalFeedId: 1,

      advertPosts: [],
      advertCursor: null,
      advertFeedId: 1

    }
  },
  methods: {
    ...mapActions('posts', ['getMyPersonalPosts', 'getMyAdvertPosts']),
    getPersonalPosts (loader) {
      this.getMyPersonalPosts(this.personalCursor)
      .then(response => {
        console.log('personal', response)
        this.personalPosts.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.personalCursor = response.data.data.meta.cursor
        }
      })
    },

    getAdvertPosts (loader) {
      this.getMyAdvertPosts(this.advertCursor)
      .then(response => {
        this.advertPosts.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.advertCursor = response.data.data.meta.cursor
        }
      })
    }
  }
}
</script>

<style lang="scss">
.profile-my-posts {
  padding-top: 110px;
  max-width: 660px;
  margin-left: 38px;
  &__tabs {
    & .ltabs__header {
      width: 40%;
      height: 50px;
      margin-bottom: 47px;
    }
  }
}
</style>